import React, { KeyboardEventHandler, useCallback, useContext, useEffect, useRef, useState } from "react";

import { useLazyQuery, useMutation } from "@apollo/client";

import GlobalContext from "@/contexts/Global/GlobalContext";
import { UserContextType } from "@/contexts/User/types";
import UserContext from "@/contexts/User/UserContext";
import { DeviceTypeEnum } from "@/globalTypes";
import { getFreeMinutesForUser } from "@/utils/commonUtils";
import { detectDeviceType } from "@/utils/env";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";
import SessionWaitingBlock from "@components/common/sessionWaitingBlock";

import { setViToken } from "../../account/voximplant";

import { init, loginWithToken } from "./authorization";
import { FEED_SCROLL_GAP_PX, SCROLL_BOTTOM_TIMEOUT_MS, TYPING_NOTIFICATION_TIMEOUT_MS } from "./constants";
import type { createConversation, createConversationVariables } from "./graphql/__generated__/createConversation";
import { getCallTokenForChat } from "./graphql/__generated__/getCallTokenForChat";
import type { joinConversation, joinConversationVariables } from "./graphql/__generated__/joinConversation";
import { CREATE_CONVERSATION } from "./graphql/CREATE_CONVERSATION";
import { GET_CALL_TOKEN_QUERY } from "./graphql/GET_CALL_TOKEN_QUERY";
import { JOIN_CONVERSATION } from "./graphql/JOIN_CONVERSATION";
import MS from "./messenger.service";
import TextChatFooter from "./textChatFooter";
import TextChatHeader from "./textChatHeader";
import { Message, TextChatProps } from "./types";
import { convertToDisplayMessage } from "./utils";

import "./styles.scss";

const MessengerService = MS; // fixes hot reload

// todo: extract authorisation to hook
const TextChat = (props: TextChatProps) => {
  const {
    sessionId,
    conversationUuid,
    viName1,
    receiver,
    outOfMoneyTime,
    isSessionInProgress,
    setIsSessionInProgress,
    endSessionCallback,
    getSessionRefetch,
  } = props;
  const { isAlertShown, setIsAlertShown, isExpert } = useContext(GlobalContext);
  const { isUserLoggedIn, balance, freeMinutesCount } = useContext<UserContextType>(UserContext);
  const [
    getCallToken,
    { loading: callTokenLoading, error: callTokenError, data: callTokenData },
  ] = useLazyQuery<getCallTokenForChat>(GET_CALL_TOKEN_QUERY);
  const [viKey, setViKey] = useState<string | null>(null);

  const [createConversation, {
    loading: createConversationLoading,
    error: createConversationError,
    data: createConversationData,
  }] = useMutation<createConversation, createConversationVariables>(CREATE_CONVERSATION, { fetchPolicy: "network-only" });

  const [joinConversation, {
    loading: joinConversationLoading,
    error: joinConversationError,
    data: joinConversationData,
  }] = useMutation<joinConversation, joinConversationVariables>(JOIN_CONVERSATION, { fetchPolicy: "network-only" });

  const [isChatVisible, setIsChatVisible] = React.useState<boolean>(false);
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false); // flag for correct hot reload
  const [conversation, setConversation] = React.useState<any>(); // todo: probably don't store conversation in state
  const [message, setMessage] = React.useState<string>("");
  // const [imageUrl, setImageUrl] = React.useState<string>("https://i.natgeofe.com/n/548467d8-c5f1-4551-9f58-6817a8d2c45e/NationalGeographic_2572187_square.jpg");
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [isSessionEndsSoon, setIsSessionEndsSoon] = useState(false);
  const [isShowGiftsBlock, setIsShowGiftsBlock] = useState(false);
  const [isOtherSideTyping, setIsOtherSideTyping] = useState<boolean>(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState<number>(0);
  const receiverUserIdRef = useRef<number>();
  const textareaRef = useRef<HTMLTextAreaElement>();
  const feedContainerRef = useRef();

  const sessionEndsSoonCallback = useCallback(
    (isEndsSoon: boolean) => {
      setIsSessionEndsSoon(isEndsSoon);
    },
    [],
  );

  useEffect(() => {
    if (!viName1) {
      return;
    }
    if (isAuthorized) {
      return;
    }

    console.log("viName", viName1);
    init(viName1, (key: string) => {
      // todo: maybe change callback logic
      if (key) {
        console.log("viKey", key);
        setViKey(key);
      }
    });
  }, [isAuthorized, viName1]);

  // VI login
  useEffect(() => {
    if (isAuthorized) {
      return;
    }
    if (viKey) {
      getCallToken({ variables: { key: viKey } });
    }
  }, [getCallToken, isAuthorized, viKey]);

  const scrollFeedToBottom = () => {
    const chatContent = document.querySelector(".text-chat__messages");

    if (chatContent) {
      const lastMessage = chatContent.querySelector(".text-chat__message:last-child");

      if (lastMessage) {
        lastMessage.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const onMessageSent = useCallback((e) => {
    if (e.initiator === receiverUserIdRef.current && feedContainerRef.current) {
      const feedScroll = feedContainerRef.current.scrollTop;
      if (-feedScroll > FEED_SCROLL_GAP_PX) {
        setUnreadMessageCount((oldValue) =>
          oldValue + 1);
      } else {
        setTimeout(() =>
          scrollFeedToBottom(), SCROLL_BOTTOM_TIMEOUT_MS);
      }
    } else {
      setTimeout(() =>
        scrollFeedToBottom(), SCROLL_BOTTOM_TIMEOUT_MS);
    }
    setMessages(
      (oldDialog) =>
        [...oldDialog, convertToDisplayMessage(e)],
    );
    setIsOtherSideTyping(false);
  }, []);

  const onNotifyTyping = useCallback((e) => {
    if (MessengerService.messenger && MessengerService.messenger.currentUserId !== e.initiator) {
      setIsOtherSideTyping(true);
    }
  }, []);

  const onSetStatus = useCallback((e) => {
    if (e.initiator === receiverUserIdRef.current && e.online) {
      setIsSessionInProgress(true);
    }
  }, [setIsSessionInProgress]);

  const restoreConversationHistory = useCallback((conversationToRestore) => {
    MessengerService.get().retransmitMessageEvents(conversationToRestore).then((messageEvents) => {
      setMessages(messageEvents.map(convertToDisplayMessage));
      getSessionRefetch();
    });
    MessengerService.messenger.setStatus(true);
  }, [getSessionRefetch]);

  const startChat = useCallback(async () => {
    const initMessagingPromise = MessengerService.get()
      .init({ onMessageSent, onNotifyTyping, onSetStatus })
      .catch((error: any) => {
        console.log("VI get MessengerService failed", error);
      });
    if (conversationUuid) {
      initMessagingPromise
        .then(() =>
          MessengerService.get().getConversation(
            conversationUuid,
          ))
        .catch((error: any) => {
          console.log("2 party: VI get conversation failed", error);
        })
        .then((data: any) => {
          setIsChatVisible(true);
          setConversation(data.conversation);
          setIsAuthorized(true);
          receiverUserIdRef.current = data.conversation.participants
            .map((participant) =>
              participant.userId)
            .filter((userId: number) =>
              userId !== MessengerService.messenger.currentUserId)
            ?.[0];
          // joinConversation call is not needed if conversation is already running
          // This may be when user reloads page, leaving page for a moment or leaving tab on moblie
          if (isSessionInProgress) {
            restoreConversationHistory(data.conversation);
          } else {
            joinConversation({ variables: { sessionID: parseInt(sessionId || "0", 10) } });
          }
        })
        .catch((error: any) => {
          console.log("2 party: Getting initial data failed", error);
        });
    } else {
      initMessagingPromise
        .then(() =>
          MessengerService.get().getUser(
            `${receiver.voxImplantInfo.userName}@${process.env.GATSBY_VI_APPLICATION_NAME}.${process.env.GATSBY_VI_ACCOUNT_NAME}`,
          ))
        .catch((error: any) => {
          console.log("1 party: VI getUser failed", error);
        })
        .then((userData: any) => {
          receiverUserIdRef.current = userData?.user?.userId;
          return MessengerService.get().createPublic(userData?.user?.userId);
        })
        .catch((error: any) => {
          console.log("1 party: VI create conversation failed", error);
        })
        .then((data: any) => {
          setIsChatVisible(true);
          setConversation(data.conversation);
          setIsAuthorized(true);
          createConversation({ variables: {
            sessionID: parseInt(sessionId || "0", 10),
            voxConversationID: data.conversation.uuid,
          } });
        })
        .catch((error: any) => {
          console.log("1 party: Getting initial data failed", error);
        });
    }
  }, [
    conversationUuid,
    createConversation,
    isSessionInProgress,
    joinConversation,
    onMessageSent,
    onNotifyTyping,
    onSetStatus,
    receiver.voxImplantInfo.userName,
    restoreConversationHistory,
    sessionId,
  ]);

  useEffect(() => {
    if (isAuthorized) {
      return;
    }
    if (!callTokenLoading && !callTokenError && callTokenData) {
      setViToken(callTokenData.getCallToken);
      loginWithToken(callTokenData.getCallToken).then(() => {
        startChat();
      });
    }
    if (!callTokenLoading && callTokenError) {
      console.log("callTokenError", callTokenError);// todo: handle error
    }
  }, [callTokenError, callTokenLoading, callTokenData, isAuthorized, startChat]);

  // todo: ugly gag for development. Remove after https://3.basecamp.com/5069474/buckets/28329794/todos/7193505999
  useEffect(() => {
    if (isAlertShown) {
      setIsAlertShown(false);
    }
  }, [isAlertShown, setIsAlertShown]);

  useEffect(() => {
    // todo: handle possible errors
    if (createConversationData && !createConversationError && !createConversationLoading) {
      joinConversation({ variables: { sessionID: parseInt(sessionId || "0", 10) } });
    }
  }, [createConversationData, createConversationError, createConversationLoading, joinConversation, sessionId]);

  useEffect(() => {
    // todo: handle possible errors
    if (joinConversationData && !joinConversationError && !joinConversationLoading) {
      restoreConversationHistory(conversation);
    }
  }, [
    conversation,
    joinConversationData,
    joinConversationError,
    joinConversationLoading,
    restoreConversationHistory,
  ]);

  const sendMessage = async () => {
    if (message) {
      MessengerService.get().sendMessage(conversation, message/* , [{ imageUrl } ] */);
      setMessage("");
      if (detectDeviceType() !== DeviceTypeEnum.Desktop) {
        textareaRef.current?.focus();
      }
    }
  };

  const onChange = (e) => {
    MessengerService.get().notifyTyping(conversation);
    setMessage(e.target.value);
  };

  const onMessageInputKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = (e) => {
    if (e.keyCode === 13) { // 13 means Enter
      e.preventDefault();
      if (
        detectDeviceType() === DeviceTypeEnum.Desktop
        && e.shiftKey === false
        && e.ctrlKey === false
        && e.metaKey === false
      ) {
        sendMessage();
      } else {
        setMessage((oldValue) =>
          `${oldValue}\n`);
      }
    }
  };

  const onFeedScroll = (e) => {
    if (!unreadMessageCount) {
      return;
    }

    const feedScroll = e.target.scrollTop;
    if (!feedScroll) {
      setUnreadMessageCount(0);
    }
  };

  // Hides typing notification
  useEffect(() => {
    if (isOtherSideTyping) {
      const clearTypingTimer = setTimeout(() =>
        setIsOtherSideTyping(false), TYPING_NOTIFICATION_TIMEOUT_MS);

      return () =>
        clearTimeout(clearTypingTimer);
    }
    return undefined;
  }, [isOtherSideTyping]);

  // Runs timer to update current user online/offline status
  useEffect(() => {
    if (isAuthorized && MessengerService.messenger && receiverUserIdRef.current && !isSessionInProgress) {
      // todo: uncomment when up-to-date online/offline status is needed
      // const setStatusTimer = setInterval(() => {
      //   MessengerService.messenger.setStatus(true);
      // }, USER_SET_STATUS_PERIOD_MS);
      // MessengerService.messenger.setStatus(true);
      MessengerService.messenger.unsubscribe([], true); // Unsubscribing from all users in case of previous chat crush
      MessengerService.messenger.subscribe([receiverUserIdRef.current]); // Subscribing to receiver status changes

      return () => {
        // clearInterval(setStatusTimer);
        MessengerService.messenger.setStatus(false);
        MessengerService.messenger.unsubscribe([], true); // Unsubscribing from all users
      };
    }

    return undefined;
  }, [isSessionInProgress, isAuthorized]);

  if (!isSessionInProgress) {
    return (
      <div className="text-chat__waiting-container">
        <div className="text-chat__waiting">
          <SessionWaitingBlock
            text={`Ждём ${isExpert ? `клиента` : `эксперта`} в\u00a0чате…`}
            subtextTop={`${isExpert ? `Клиент` : `Эксперт`} скоро зайдёт и\u00a0напишет вам.`}
            subtextBottom={isExpert ? `` : `Задайте ему свой вопрос и\u00a0дождитесь, пока\u00a0эксперт ответит`}
            isRemoteVideoOn={false}
            isConnected={false}
            receiver={receiver}
          />
          <div className="text-chat__waiting-controls">
            <div
              className="text-chat__waiting-cancel"
              onClick={() =>
                endSessionCallback(parseInt(sessionId, 10))}
            >
              <Icon type={IconTypeEnum.Close} size={IconSizeEnum.Size48} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  const freeMinutesForUser = getFreeMinutesForUser(isUserLoggedIn, freeMinutesCount);
  const isFFAllowFirstChatSessionWithoutPaymentOn = process.env.GATSBY_ALLOW_FIRST_CHAT_SESSION_WITHOUT_PAYMENT === "true";
  const newUserFreeMinutesCount = parseInt(process.env.GATSBY_FREE_MINUTES_COUNT ?? "0", 10);
  const isTrial = isFFAllowFirstChatSessionWithoutPaymentOn && freeMinutesForUser === newUserFreeMinutesCount;

  return (
    <div className="text-chat__container">
      <div className="text-chat">
        <TextChatHeader
          endSessionCallback={endSessionCallback}
          isExpert={isExpert}
          outOfMoneyTime={outOfMoneyTime}
          receiver={receiver}
          sessionEndsSoonCallback={sessionEndsSoonCallback}
        />

        <div className="text-chat__middle" ref={feedContainerRef} onScroll={onFeedScroll}>
          <div className="text-chat__messages">
            {isChatVisible && (
              <>
                {!isExpert && (!isTrial || !!balance?.amount) && (
                  <div className="text-chat__greeting">
                    <div className="text-chat__greeting-title">Консультация началась.</div>
                    <div className="text-chat__greeting-subtitle">Задайте ваш вопрос эксперту👇</div>
                    <div className="text-chat__greeting-description">
                      Чтобы консультация не прервалась:
                      <ul>
                        <li>не блокируйте телефон</li>
                        <li>не закрывайте чат</li>
                        <li>не переходите куда-либо</li>
                      </ul>
                    </div>
                  </div>
                )}
                {!isExpert && isTrial && !balance?.amount && (
                  <div className="text-chat__greeting">
                    <div className="text-chat__greeting-title">Вы на бесплатной консультации🎉</div>
                    <div className="text-chat__greeting-subtitle">
                      {`Она продлится ${parseInt(process.env.GATSBY_FREE_MINUTES_COUNT ?? "7", 10)} минут.`}
                    </div>
                    <div className="text-chat__greeting-subtitle">Задайте ваш вопрос эксперту👇</div>
                    <div className="text-chat__greeting-description">
                      Чтобы консультация не прервалась:
                      <ul>
                        <li>не блокируйте телефон</li>
                        <li>не закрывайте чат</li>
                        <li>не переходите куда-либо</li>
                      </ul>
                    </div>
                  </div>
                )}
                {messages.map((message) =>
                  (
                    <div
                      key={message.uuid}
                      className={`text-chat__message ym-hide-content sentry-mask${message.sender === MessengerService.messenger.currentUserId ? " my" : ""}`} // Hides message from Webvisor and Sentry for safety
                      data-sentry-mask // Hides message from Sentry for safety
                    >
                      <pre>{message.text}</pre>
                    </div>
                  ))}
              </>
            )}
            {isOtherSideTyping && (
              <div className="text-chat__typing-text">
                {receiver.name}
                {" "}
                печатает...
              </div>
            )}
          </div>
        </div>
        <TextChatFooter
          getSessionRefetch={getSessionRefetch}
          isExpert={isExpert}
          isSessionEndsSoon={isSessionEndsSoon}
          isSessionInProgress={isSessionInProgress}
          isShowGiftsBlock={isShowGiftsBlock}
          message={message}
          onChange={onChange}
          onMessageInputKeyDown={onMessageInputKeyDown}
          receiver={receiver}
          scrollFeedToBottom={scrollFeedToBottom}
          sendMessage={sendMessage}
          sessionId={sessionId}
          setIsShowGiftsBlock={setIsShowGiftsBlock}
          setUnreadMessageCount={setUnreadMessageCount}
          textareaRef={textareaRef}
          unreadMessageCount={unreadMessageCount}
        />
      </div>
    </div>
  );
};

export default TextChat;
